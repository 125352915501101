<script setup>
import TopBar from "~/components/organisms/TopBar.vue";
import Header from "~/components/organisms/Header.vue";
import {defineAsyncComponent} from 'vue'
import {useStartupStore} from "~/store/sturtupStore";
import {useCartStore} from "~/store/cartStore.js";
import GreyBlock from "~/components/templates/GreyBlock.vue";
import {useUserStore} from "~/store/userStore.js";
import FooterBlock from "~/components/organisms/FooterBlock.vue";
import {fetchWithErrorHandling} from '~/composables/fetchWithErrorHandling';

const SideMenu = defineAsyncComponent({
  loader: () => import('~/components/organisms/SideMenu.vue'),
  onError(error, retry, fail, attempts) {
    console.error(`Error loading Side Menu in Clean ${attempts} спроб:`, error)
    fail()
  }
})

const userStore = useUserStore();

const startupStore = useStartupStore();
const cartStore = useCartStore();

onMounted(() => {
  cartStore.getContent()
})

useHead({
  meta: [
    {name: 'robots', content: 'index, follow'}
  ],
})
</script>

<template>
  <div class="main-layout">
    <GreyBlock :padding="0">

      <div class="g-32">
        <header>
          <TopBar/>
          <Header mode="clean"/>
        </header>
        <SideMenu v-if="startupStore.isMenuInitialised"/>
        <main>
          <div class="g-128">
            <slot/>
          </div>
        </main>

      </div>
    </GreyBlock>

    <FooterBlock/>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/mixins.scss";

main {
  @include smd {
    padding: 0;
  }
}

.checkout-container {
  width: 1218px;
  max-width: 100%;
  margin: auto;
}

.footer-content {
  width: 717px;
  margin: auto 171px;
  min-height: 556px;
  max-width: 100%;

  @include smd {
    padding-bottom: 40px;
  }

  .title {
    font-size: 26px;

    @include smd {
      font-size: 20px;
      z-index: 2;
    }
  }

  .contact-block {
    min-height: 158px;
    min-width: 218px;
    background: $white;
    box-shadow: 0 4px 45px 0 #F9F9F9;
    z-index: 2;

    a {
      display: flex;
      flex-direction: column;
    }

    svg {
      stroke: $black;
      height: 22px;
    }

    strong {
      font-size: 16px;
    }
  }
}

.disabled {
  pointer-events: none;
}

.continent {
  position: absolute;
  top: 0;
  bottom: 0;
  opacity: 0.4;

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }

  img {
    width: 100%;

    @include smd {
    }
  }
}

</style>
